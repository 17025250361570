import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Login } from "./pages/Login";

import { useAtom } from "jotai";
import { loadingAtom, userAtom } from "./atoms";
import { getCurrentUser } from "./api";
import ProtectedRoute from "./components/ProtectedRoute";
import { Dashboard } from "./pages/Dashboard";

function App() {
  const [, setUser] = useAtom(userAtom);
  const [loading, setLoading] = useAtom(loadingAtom);

  const getUser = useCallback(async () => {
    try {
      const { data } = await getCurrentUser();
      setUser(data);
    } catch (err) {
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, [setUser]);

  useEffect(() => {
    try {
      getUser();
    } catch {
      setUser(null);
      setLoading(false);
    }
  }, [getUser, setUser]);

  if (loading) {
    return <></>;
  }
  //comment to deploy
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      ></Route>

      <Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
    </Routes>
  );
}

export default App;
